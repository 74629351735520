var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scale",staticClass:"wrapper"},[_c('div',{staticClass:"scale"},[_c('transition-group',{staticClass:"custom-table",attrs:{"name":"scale-items","tag":"div"}},_vm._l((_vm.getAllLabels),function(labelObj){return _c('div',{key:labelObj.id,staticClass:"label-color-wrapper"},[_c('div',{staticClass:"color-wrapper",style:({
            width:
              labelObj.id === _vm.getAllLabels.length - 1
                ? '0'
                : '2.285714285714286rem',
            backgroundColor: _vm.getAllColors[labelObj.id],
            borderWidth:
              labelObj.id === _vm.getAllLabels.length - 1
                ? '0'
                : labelObj.id === 0
                ? '1px'
                : '1px 1px 1px 0',
          })}),_c('div',{ref:("label" + (labelObj.id)),refInFor:true,staticClass:"label-wrapper"},[_c('p',[_vm._v(_vm._s(labelObj.label))])])])}),0)],1),_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"description"},[_c('p',[_vm._v(_vm._s(_vm.description))])])])}
var staticRenderFns = []

export { render, staticRenderFns }